@import '~@fortawesome/fontawesome-free-webfonts/css/fontawesome.css';
@import 'app/shared/styles/mixins';
@import 'app/shared/styles/form';
@import 'app/shared/styles/button';
@import 'app/shared/styles/sticky-header';
@import 'app/shared/styles/sidenav';

:root {
  --secondary-color: #5738ff;
}
$primary-color: #461e7d;
$secondary-color: #5738ff;
$gray: #888888;

global-nav {
  position: fixed;
  width: 100%;
  z-index: 999;
}

global-footer {
  display: block;
  padding: 40px 25px 25px;
}

lib-ngx-deeplinker {
  button {
    background-color: #fff;
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
    padding: 6px 10px !important;
  }
}

.main-container {
  input:focus,
  input:active,
  input:hover,
  select:focus,
  select:active,
  select:hover,
  textarea:focus,
  textarea:active,
  textarea:hover {
    border: none;
  }
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-warning hr {
  border-top-color: #ffe8a1;
}

.alert-warning .alert-link {
  color: #533f03;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media screen and (max-width: 768px) {
}

.main-container[mobile='true'] .sticky-header {
  top: 0;
}

.group-error {
  font-family: var(--font) !important;
  font-size: 10.5px !important;
  letter-spacing: 0 !important;
}

.sidenav-component-container::-webkit-scrollbar {
  width: 8px;
}

.sidenav-component-container::-webkit-scrollbar-track {
  border-radius: 30px;
}

.sidenav-component-container::-webkit-scrollbar-thumb {
  border-radius: 30px;
  background-color: var(--primary-color);
}
